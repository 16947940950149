<template>
  <div id="app">
  <!--vmetaHome_en></vmetaHome_en-->
<router-View/>
</div>
</template>

<script>
//import vmetaHome_zh from '@/views/vmetaHome_zh.vue'
//import vmetaHome_en from './views/vmetaHome_en.vue';

export default {
  name: 'App',
  components: {
    //vmetaHome_zh,
    //vmetaHome_en
  }
}
</script>

<style>
@font-face {
  font-family: 'zitiming';
  src: url('@/assets/font/lianmeng.ttf');
}

#app {
  font-family:  '苹方', fredokaOne;
  text-align: center;
}
</style>
