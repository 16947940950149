//import Vue from 'vue';
import{createRouter,createWebHashHistory} from "vue-router";

//import vmetaHome_en from '@/views/vmetaHome_en';
//import vmetaHome_zh from '@/views/vmetaHome_zh';

//Vue.use(Router);
const routes=[
    {
        path:'/',
        redirect:"/home",
        name:'home',
    },
    {
        path:"/home",
        component:()=>import('@/views/vmetaHome_zh.vue'),
    },
   
    {
        path:'/en',
        name:'en',
        component: () => import ( "@/views/vmetaHome_en.vue")
    }

]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

/*const originalPush = router.prototype.push
router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}*/
export default router